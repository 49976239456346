<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
                <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

                </v-breadcrumbs>
            </div>
        </v-row><br><br>
        <!-- <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Dashboard
            </router-link>
            /
            <router-link :to="{name: 'admin-master' }" class="root">
                Admin Master
            </router-link>
            <span class="child">/ User Type </span>
        </v-row> -->
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">
                <span>User Type</span>
            </v-card-title>

            <div v-if="init_loading">
                <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
            </div>
            <div v-else>
                <v-card-text>
                    <div class="add-section">
                        <div style="text-align: center;">
                            <v-col>
                                <v-form ref="form">
                                    <label class="add-text">Add New User Type</label><span style="color: red;"> *</span>
                                    <!-- <v-text-field class="text" v-model="userType" outlined dense :rules="[rules.required]" clearable required></v-text-field> -->

                                    <v-text-field class="text" v-model.trim="userType" 
                                         outlined dense :rules="[rules.required]" clearable
                                        required></v-text-field>

                                    <v-btn color="success" @click="addUserType">Add</v-btn>
                                </v-form>
                            </v-col>
                        </div>
                    </div>

                    <template>
                        <div class="text-center">
                            <v-dialog v-model="dialog" width="500">
                                <v-card>
                                    <v-card-title class="grey lighten-2">
                                        Edit User Type
                                    </v-card-title>

                                    <v-card-text style="padding-bottom: 0px;">
                                        <label class="add-text">User Type</label><span style="color: red;"> *</span>
                                        <!-- <v-text-field class="text" v-model="editUserType" outlined dense :rules="[rules.required]" clearable required></v-text-field> -->
                                        <v-text-field class="text" v-model.trim="editUserType" outlined dense :rules="[rules.required]" clearable
                                            required></v-text-field>

                                    </v-card-text>

                                    <!-- <v-divider></v-divider> -->

                                    <div class="text-center">
                                        <v-spacer></v-spacer>
                                        <v-btn class="m-3" @click="dialog = false">
                                            Close
                                        </v-btn>
                                        <v-btn class="m-3" @click="saveEdit()">Update</v-btn>
                                    </div>
                                </v-card>
                            </v-dialog>
                        </div>
                    </template>

                    <template v-if="userType_list != null">
                        <v-data-table :headers="headers" :items="userType_list" :search="search" class="elevation-1 nowrap">
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table"
                                        single-line hide-details></v-text-field>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.edit="{ item }">
                                <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
                                <v-icon v-if="item" class="mr-2" @click="updateUserType(item)">mdi mdi-pencil-box</v-icon>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                                <v-switch v-model="item.userTypeIsactive" @change="editItem(item)"></v-switch>
                            </template>
                            <template v-slot:item.delete="{ item }">
                                <v-icon v-if="item" class="mr-2" @click="deleteUserType(item)"> mdi mdi-delete</v-icon>
                            </template>
                        </v-data-table>
                    </template>
                </v-card-text>
            </div>
        </v-card>
    </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
    data: () => ({
        snackbar_msg: "",
        overlay: false,
        init_loading: false,
        snackbar: false,
        color: "",
        org: null,
        dialog: false,
        search: "",
        userType: null,
        edit_btn_load: false,
        editUserType: null,
        editUserTypeId: null,
        userTypeSA: null,
        items: [
            {
                text: 'Dashboard',
                disabled: false,
                href: '/home',
            },
            {
                text: localStorage.getItem("jhhjbyj"),
                disabled: false,
                href: '/sublink',
            },
            {
                text: 'Admin Master',
                disabled: false,
                href: '/admin-master',
            },
            {
                text: 'User Type',
                disabled: true,
                href: '',
            },
        ],
        rules: {
            required: (value) => !!value || "Required.",
        },
        userType_list: null,
        headers: [
            { text: "Sr no", value: "srno" },
            { text: "Name", value: "userType" },
            { text: "Edit", value: "edit" },
            { text: "Toggle", value: "actions", sortable: false },
            { text: "Delete", value: "delete" },
        ],
    }),
    watch: {},
    methods: {
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        editItem(item) {
            axios
                .post("/admin/editAdminUserTypeOperation", item)
                .then((res) => {
                    this.sec_mode_list = res.data.user_list;
                    this.showSnackbar("#4CAF50", "User Type updated successfully...");
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.edit_btn_load = false;
                    axios;
                });
        },

        updateUserType(item) {
            this.dialog = true;
            this.editUserType = item.userType;
            this.editUserTypeId = item.userTypeId;
        },

        saveEdit(item) {
            console.log(item);
            console.log('pp', this.editUserType);
            const data = {
                usertType: this.editUserType,
                userTypeId: this.editUserTypeId,
            };
            if (this.editUserType !== null) {
                axios
                    .post("/admin/editUsertype", data)
                    .then((res) => {
                        if (res.data.msg == "success") {
                            this.sec_mode_list = res.data.user_list;
                            this.showSnackbar("#4CAF50", "User Type updated successfully...");
                            this.onload();
                            this.dialog = false;
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        this.editUserType = null;
                        axios;
                    });
            } else {
                this.showSnackbar("#b71c1c", "Please enter User Type");
            }
        },
        deleteUserType(item) {
            const data = {
                userTypeId: item.userTypeId,
            };
            axios
                .post("/admin/deleteUserType", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.showSnackbar("#4CAF50", "UserType Deleted Successfully  !!");
                        this.onload();
                    } else if (res.data.msg == "exp") {
                        this.showSnackbar("#b71c1c", "Can not be Deleted!!!");
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    window.console.log(error);
                });
        },
        onload() {
            this.init_loading = true
            axios
                .post("/admin/getMasterUserType")
                .then((res) => {
                    this.org = res.data.org;
                    this.userType_list = res.data.userType;
                    this.init_loading = false
                    this.userTypeSA = res.data.userType_SA
                    //this.showSnackbar("#4CAF50","Success")
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.init_loading = false
                });
        },

        addUserType() {
            console.log(this.userType);
            // if(this.userType.userType){
            // this.userType.userType = this.userType.trim()
            // }
            const data = {
                userType_name: this.userType,
            };

            if (this.userType) {
                axios
                    .post("/admin/addUserType", data)
                    .then((res) => {
                        this.org = res.data.org;
                        this.userType_list = res.data.userType;
                        this.userType_list = res.data.user_list;
                        if (res.data.msg == "success") {
                            this.showSnackbar("#4CAF50", "User Type added successfully...");
                            this.userType = null;
                            this.$refs.form.reset()
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => { });
            } else {
                this.showSnackbar("#b71c1c", "Please enter User Type");
            }
        },
    },
    mounted() {
        this.onload();
    },
};
</script>
<style scoped>
.heading {
    background-color: #3f51b5;
    padding: 0.3rem;
    color: white;
}

.v-icon {
    cursor: pointer;
}

.text>>>.v-input__slot {
    background: white !important;
    border-color: hsla(190, 99%, 30%, 1) !important;
}
</style>
